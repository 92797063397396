<div class="terms-and-conditions-skeleton">
    <div>
        <span class="informative-label">{{ "INFORMATIVE_TERMS_AND_CONDITIONS" | translate}}</span>
    </div>
    <div>
        <div class="chk-t-c">
            <input type="checkbox" id="chk_accept" (change)="onCheckboxChange($event)" [disabled]="disabled"
                [checked]="isCheckboxChecked">
            <span class="terms-and-condition-text">
                {{labelFirstPart}}
                <a [href]="folderPath + urlPdf" download="{{urlPdf}}" class="text-link terms-and-condition-text">
                    {{textLink}}
                </a>
                {{labelLastPart}}
            </span>
        </div>
        <div *ngIf="showError && !isCheckboxChecked" class="error txt-puro-red">
            <span class="terms-and-condition-text">{{error}}</span>
        </div>
    </div>
</div>
