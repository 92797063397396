import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';
import { OBPPQuickPayService } from 'src/app/services/quick-pay/quick-pay.service';
import {
  NgbdSortableHeader,
  SortEvent,
} from 'src/app/common/bootstrap-sortable-header/sortable-header.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OBPPModalDialogComponent } from '../../error-component/modal-dialog/modal-dialog.component';
import { Router } from '@angular/router';
import { OBPPQuickPayInvoiceComponent } from 'src/app/common/shared/pop-up-modal/modal-quick-pay-invoice/modal-quick-pay-invoice.component';
import { OBPPQuickPayCPWAService } from 'src/app/services/cpwa-services/quickpay-cpwa.services';
import { CPWAQuickPayData, MonerisReceiptData } from 'src/app/models/obpp-invoice.model';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import { LocalStorageService } from 'src/app/services/global/local-storage.service';
import { MonerisCheckout, ShoppingCartItem } from 'src/app/common/models/monerisCheckout';
import { MonerisIntegrationComponent } from 'src/app/common/shared/moneris-integration/moneris-integration/moneris-integration.component';
import { CheckTermsConditionComponent } from '../../check-terms-condition/check-terms-condition.component';
import { TranslateService } from '@ngx-translate/core';
import { InvoicePayment } from 'src/app/common/models/invoicePayment';
import { InvoicePaymentService } from 'src/app/services/invoice-payment/invoice-payment.service';
import { MonerisIntegrationService } from 'src/app/services/moneris-integration/moneris-integration.service';

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

declare var monerisCheckout: any;  // Declare monerisCheckout

@Component({
  selector: 'obpp-quick-payment',
  templateUrl: './obpp-quick-payment.component.html',
  styleUrls: ['./obpp-quick-payment.component.scss'],
})
export class OBPPQuickPayment implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers?: QueryList<NgbdSortableHeader>;
  @ViewChild('init__payment') init__payment!: ElementRef;
  @ViewChild('checkAllInvoice') checkAllInvoice: ElementRef | undefined;
  @ViewChild('checkInvoice') checkInvoice: ElementRef | undefined;
  @ViewChild(CheckTermsConditionComponent) checkTermsComp!: CheckTermsConditionComponent;

  content?: string;
  cpwaPayload: any;

  FromEmployeeCSR: boolean = false;
  paymentSuccessFlag: boolean = false;
  showQuickPay: boolean = false;
  paymentInfo: string = ''; // will be deleted
  showIFrame: boolean = false;
  declinedMessage: boolean = false;
  amountInvalidFlag: boolean = false;

  invoiceLength = 0;
  cadInvoiceCount = 0;
  usdInvoiceCount = 0;
  paymentAmount = 0;
  fieldErrorsObj = [];
  QuickPaymentForm: FormGroup;
  errMsgFactory: any;

  quickPaymentData: any = [];
  invoiceList: any = [];
  allInvoiceList: any = [];
  invoiceListGrid: any = [];
  selectedInvoice: string = '';
  loadText: string = '';

  cpwaQuickPayData!: CPWAQuickPayData;
  paymentInformation: any = [];
  paymentURL = '';
  checkAllInvoices: boolean = false;

  paymentCurrency: string = 'CAD';

  @ViewChild('cad')
  cad!: ElementRef;

  @ViewChild('usd')
  usd!: ElementRef;
  cadTotal: number = 0;
  usdTotal: number = 0;
  selectNext: boolean = true;
  paymentDate: any;

  termsConditionsAgree = false;
  showError: boolean = false;

  monerisTicketId: string = '';
  errorState: boolean = true;
  receipt!: MonerisReceiptData;
  errorLoadingMonerisIframe: boolean = true;
  mcoIframeLoaded: boolean = false;

  showCompleteIframe: boolean = true;
  selectedInvoiceList: any = [];
  monerisEnv: string = '';

  constructor(
    private dataSharingService: DataSharingService,
    private obppLoginService: OBPPLoginService,
    private modalService: NgbModal,
    private router: Router,
    private fb: FormBuilder,
    private obppQuickPayService: OBPPQuickPayService,
    public locstore: LocalStorageService,
    public monerisComponent: MonerisIntegrationComponent,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    public invoicePaymentService: InvoicePaymentService,
    private monerisIntegrationService: MonerisIntegrationService
  ) {
    this.QuickPaymentForm = this.fb.group({
      invoiceType: ['', Validators.required],
      accountNumbers: '',
      invoiceNumbers: ['', Validators.required],
      paymentAmount: ['', Validators.required],
      emailAddress: [
        '',
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,7}$'),
      ],
    });
    this.paymentURL = this.obppLoginService.getCPWAPaymentURL();
    this.translate.get('MESSAGE_LOADING').subscribe((translation: string) => {
      this.loadText = translation;
    })
  }

  ngOnInit() {
    this.monerisIntegrationService.getMonerisEnvironment().subscribe({
      next: (env: any) => { 
        this.monerisEnv = env.response;
      },
      error: (error: any) => { 
        this.monerisEnv = 'prod';
      }
    });

    this.showQuickPay = true;
    this.quickPaymentData = this.obppQuickPayService.getQuickPayData();
    this.selectedInvoice = this.obppQuickPayService.getSelectedInvoice();
    this.paymentCurrency = 'CAD';
    if (this.quickPaymentData?.object?.obppInvoices) {
      this.invoiceList = this.quickPaymentData?.object?.obppInvoices;
      this.paymentAmount = 0;
      this.invoiceLength = 0;
      this.paymentCurrency = this.invoiceList[0].invoiceCurrency;
      this.invoiceList.forEach((item: any) => {
        item.isChecked = false;
        item.paymentAmount = 0;
        item.paymentCurrency = item.invoiceCurrency;
        if (this.selectedInvoice == item.invoiceNumber) {
          this.selectNext = false
          this.paymentCurrency = item.invoiceCurrency;
          item.isChecked = true;
          item.paymentAmount = parseFloat(item.balanceDue);
          this.paymentAmount += parseFloat(item.balanceDue);
          this.invoiceLength++;
        } else {
          item.paymentAmount = 0;
        }
      });
      this.allInvoiceList = this.cloneObject(this.invoiceList);
    } else {
      this.invoiceList = [];
      this.allInvoiceList = [];
      this.invoiceLength = 0;
      this.paymentAmount = 0;
    }
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers?.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction || column) {
      this.invoiceList = [...this.invoiceList].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  checkAllInvoiceList(event: any): void {
    event.preventDefault(); 
    if (this.checkAllInvoice) {
      if(this.checkAllInvoice.nativeElement.checked){
        this.checkAllInvoices=false
        this.selctAllInvoices(event);
      }else if (!this.checkAllInvoice.nativeElement.checked){
       this.checkAllInvoices=true;
        this.selctAllInvoices(event);
      }
      this.checkAllInvoice.nativeElement.checked = !this.checkAllInvoice.nativeElement.checked;
      
    }
  }
  checkInvoices(event:any,isChecked:any,accountNumber:any,index:any){
    event.preventDefault(); 
    if (this.checkInvoice) {
      if(this.checkInvoice.nativeElement.checked){
        this.invoiceList[index].isChecked=false
       
      }else if (!this.checkInvoice.nativeElement.checked){
        this.invoiceList[index].isChecked=true
        //this.updatePaymentAmountFromSelect(event,index);
      }
      this.updatePaymentAmountFromSelect(event,index);
      this.checkInvoice.nativeElement.checked = !this.checkInvoice.nativeElement.checked;
      
    }
  }


  searchFunc() {
    if (this.paymentCurrency == 'CAD') {
      this.invoiceList = this.cloneObject(
        this.allInvoiceList.filter((item: any) => item.invoiceCurrency == 'CAD')
      );
    } else if (this.paymentCurrency == 'USD') {
      this.invoiceList = this.cloneObject(
        this.allInvoiceList.filter((item: any) => item.invoiceCurrency == 'USD')
      );
    } else {
      this.invoiceList = this.cloneObject(
        this.allInvoiceList.filter((item: any) => !item.invoiceCurrency)
      );
    }
  }

  cancelConfirmation() {
    let tit = 'CONFIRMATION';
    let errMsg = 'QUICK_PAY_CANCEL_TEXT_CONFIRMATION';
    this.openModal(tit, errMsg, 'YES', 'NO', 'warning');
  }

  moveToNextValidation() {
    // to load moneris
    this.showError = true; 
    if (!this.isButtonDisabled) {
      this.showIFrame = true;
      this.errorLoadingMonerisIframe = true;
      this.mcoIframeLoaded = false;
    }
  }

  popUpQuickPay() {
    this.openQuickPayPopup(
      'QUICK_PAY_TEXT',
      '',
      'CONTINUE_TEXT',
      'CANCEL_TEXT',
      'warning'
    );
  }

  payAnotherInvoiceAfterPayment() {
    this.router.navigateByUrl('/home');
  }

  registerforBilling() {
    this.router.navigateByUrl('/register');
  }

  payAnotherInvoice() {
    this.router.navigateByUrl('/home');
  }

  openModal(
    title: string,
    errorMsg: string,
    firstButtonLabel: any,
    secondButtonLabel: any,
    modalType: string
  ) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      this.returnToHome();
    });
  }

  returnToHome() {
    this.router.navigateByUrl('/home');
    this.dataSharingService.isRefreshRequired.next('home');
  }

  openQuickPayPopup(
    title: string,
    errorMsg: string,
    firstButtonLabel: any,
    secondButtonLabel: any,
    modalType: string
  ) {
    const modalRef = this.modalService.open(OBPPQuickPayInvoiceComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;
    modalRef.componentInstance.accountNumber = this.allInvoiceList.map(
      (item: any) => item.accountNumber
    );

    modalRef.componentInstance.successAction.subscribe((newList: any) => {
      if (newList?.object?.obppInvoices?.length > 0) {
        newList.object.obppInvoices.forEach((inv1: any) => {
          inv1.isChecked = false;
          inv1.paymentAmount = 0;
        });
        this.allInvoiceList.push(...newList.object.obppInvoices);
        this.invoiceList.push(...newList.object.obppInvoices);
      }
      this.checkAllInvoices = false;
    });
  }

  updatePaymentAmount(event: any) {
    this.invoiceLength = 0;
    this.paymentAmount = 0;
    this.invoiceList.forEach((item: any) => {
      item.paymentAmount =
        item.paymentAmount == '' || item.paymentAmount === null
          ? 0
          : item.paymentAmount;
      if (item.isChecked) {
        this.paymentAmount += parseFloat(item.paymentAmount);
        this.invoiceLength++;
      }
    });
  }

  updatePaymentAmountFromSelect(event: any, i: any) {
    this.invoiceLength = 0;
    this.paymentAmount = 0;
    if (this.invoiceList[i].isChecked) {
      this.invoiceList[i].paymentAmount = this.invoiceList[i].balanceDue;
    } else {
      this.checkAllInvoices = false;
      this.invoiceList[i].paymentAmount = 0;
    }
    this.invoiceList.forEach((item: any) => {
      item.paymentAmount =
        item.paymentAmount == '' || item.paymentAmount === null
          ? 0
          : item.paymentAmount;
      if (item.isChecked) {
        this.paymentAmount += parseFloat(item.paymentAmount);
        this.invoiceLength++;
      }
    });
    if (this.invoiceLength === this.invoiceList.length) {
      this.checkAllInvoices = true;
      this.selectNext = false;
    } else if (this.invoiceLength === 0) {
      this.selectNext = true;
    }else{
      this.selectNext = false;
    }
  }

  selctAllInvoices(event: any) {
    this.invoiceLength = 0;
    this.paymentAmount = 0;

    this.invoiceList.forEach((item: any) => {
      if (this.checkAllInvoices) {
        item.isChecked = true;
        if (parseFloat(item.paymentAmount) <= 0) {
          item.paymentAmount = parseFloat(item.balanceDue);
        }
        this.invoiceLength++;
        this.paymentAmount += parseFloat(item.paymentAmount);
      } else {
        item.isChecked = false;
        item.paymentAmount = 0;
        this.invoiceLength = 0;
        this.paymentAmount = 0;
      }
    });
    if (this.checkAllInvoices) {
      this.selectNext = false;
    }else if (!this.checkAllInvoices) {
      this.selectNext = true;
    }
  }

  updateOnlyAmount() {
    this.invoiceList.forEach((item: any) => {
      item.paymentAmount =
        item.paymentAmount == '' || item.paymentAmount === null
          ? 0
          : item.paymentAmount;
      if (parseFloat(item.paymentAmount) > parseFloat(item.balanceDue)) {
        item.paymentAmount = 0;
      }
    });
  }

  filterInvoiceList(invoiceList: any): ShoppingCartItem[] {
    const cartItem: ShoppingCartItem[] = [];

    invoiceList.forEach((item: any) => {
        if (item.isChecked) {
          cartItem.push({
            unit_cost: item.paymentAmount,
            quantity: '1',
            description: "{" + 
              "\"account\":\"" + item.accountNumber + 
              "\",\"invoice\":\"" + item.invoiceNumber +
              "\",\"currency\":\"" + item.invoiceCurrency +
            "\"}"
          });
        }
    });
    
    return cartItem;
  }

  loadMonerisIframe() {
    if (this.invoiceLength && this.paymentAmount) {

      this.dataSharingService.IsLoadingEnabled.next(true);
      this.updateMessageLoading();

      // FIX ME: this payload needs to meu change, property "environement" is point out to QA. 
      // Fix must be done before the deployment to PROD env.
      const monerisPayload: MonerisCheckout = {
        txn_total: this.paymentAmount.toFixed(2).toString(),
        environment: this.monerisEnv,
        language: this.localStorageService.get("lang"),
        contact_details: {
          email: this.QuickPaymentForm.get('emailAddress')?.value,
        },
        cart: {
          items: this.filterInvoiceList(this.invoiceList),
        },
        currency: this.paymentCurrency
      };

      this.showIFrame = true;
      this.monerisComponent.monerisPreloadRequest(monerisPayload).subscribe({
        next: (response: any) => { 
        if (response.response.success === 'false') {
          this.errorLoadingMonerisIframe = false;
          this.dataSharingService.IsLoadingEnabled.next(false);
          this.dataSharingService.LoadingLabelText.next('');
        } 

        if (response.response.ticket) {
          this.monerisTicketId = response.response.ticket;
          this.initMonerisIframe(response.response.ticket);
        }
        },
        error: (error: any) => { 
          console.error('Error on moneris preload request: ', error);
          this.errorLoadingMonerisIframe = true;
          this.dataSharingService.IsLoadingEnabled.next(false);
          this.dataSharingService.LoadingLabelText.next('');
        }
      });

    }
  }

  updateMessageLoading() {
    this.translate.get('MESSAGE_LOADING').subscribe((translation: string) => {
      this.loadText = translation;
    });
    this.dataSharingService.LoadingLabelText.next(this.loadText);
  }

  //Function we should use to store the checkbox value among other data
  saveInvoicePayment(){
    const InvoicePayment: InvoicePayment = {
      useremail: this.QuickPaymentForm.get('emailAddress')?.value,
      check_term_cond: this.termsConditionsAgree
    }; 

    this.invoicePaymentService.saveInvoicePayment(InvoicePayment).subscribe((response) =>{
    });
  }

  initMonerisIframe(ticketId: any) {

    // Configure Moneris Checkout
    const checkoutIframe = new monerisCheckout();

    // FIX ME: Should be changged to PROD before the deployment to PROD env.
    checkoutIframe.setMode(this.monerisEnv); 

    checkoutIframe.setCheckoutDiv('monerisCheckout', {
      ticketId,
      style: {}
    });

    checkoutIframe.startCheckout(ticketId);

    // callback from MCO, triggered if and when the iframe is properly loaded
    checkoutIframe.setCallback('page_loaded', (response: any) => {
      this.dataSharingService.IsLoadingEnabled.next(false);
      this.dataSharingService.LoadingLabelText.next('');
      this.mcoIframeLoaded = true;
    });

    // method that will hanlde all moneris events
    this.monerisCallbackHandling(checkoutIframe, ticketId);
    this.dataSharingService.IsLoadingEnabled.next(true);
  }

  monerisCallbackHandling(monerisIframeInstance: any, monerisTicketId: string) {

    // is triggered when user clicks on "checkout"
    monerisIframeInstance.setCallback('payment_submitted', (response: any) => {  
      this.updateMessageLoading();
      this.dataSharingService.IsLoadingEnabled.next(true);
    });

    // this callback is triggered when the back button from moneris is clicked
    monerisIframeInstance.setCallback('cancel_transaction', (response: any) => {
      this.showIFrame = false;
      this.showQuickPay = true;
      this.termsConditionsAgree = false;
      this.mcoIframeLoaded = false;
    });

    // triggered when the payment is completed and we get the receipt
    monerisIframeInstance.setCallback('payment_receipt', (recepeitResp: any) => {
      this.dataSharingService.IsLoadingEnabled.next(true);
      this.showCompleteIframe = false;
      this.callbackPaymentReceipt(this.monerisTicketId);
 
    });

    // triggered when user clicks on the left arrow (back)
    monerisIframeInstance.setCallback('payment_complete', (response: any) => {
      // this behaviour needs to be confirmed
      this.showIFrame = false;
      this.errorState = true;
      this.errorLoadingMonerisIframe = true;
    });

    monerisIframeInstance.setCallback('error_event', (response: any) => {
    });

    monerisIframeInstance.setCallback('page_closed', (response: any) => {
    });
  }

  callbackPaymentReceipt(monerisTicketId: string) {
    this.selectedInvoiceList = this.filterSelectedInvoiceList(this.invoiceList);

    this.monerisComponent.monerisReceiptRequest(monerisTicketId, this.paymentCurrency, this.selectedInvoiceList, null, "QuickPay-C", '', this.monerisEnv).subscribe((recepeitResp: any) => {
      const email = this.QuickPaymentForm.get('emailAddress')?.value;
      this.receipt = this.monerisComponent.monerisReceiptHandling(recepeitResp, this.selectedInvoiceList, email);

      this.errorLoadingMonerisIframe = this.receipt?.paymentSuccess;

      if (this.receipt.paymentSuccess) {
        this.showIFrame = false;
        this.showQuickPay = false;
        this.paymentSuccessFlag = true;
        this.errorLoadingMonerisIframe = true;
        this.dataSharingService.IsLoadingEnabled.next(false);
      } else {
          this.paymentSuccessFlag = false;
          this.termsAndConditionsHandler(false);
          this.termsConditionsAgree = false;
          this.errorState = false;
          this.errorLoadingMonerisIframe = false;
          this.dataSharingService.IsLoadingEnabled.next(false);
      }
      
    });
    this.monerisComponent.monerisUpdateInvoicesDataRequest(null, "QuickPay-C", monerisTicketId, this.selectedInvoiceList, this.paymentCurrency, this.monerisEnv, false).subscribe();
  }

  backToInvoiceList() {
    this.errorLoadingMonerisIframe = false;
    this.showIFrame = false;
    this.showQuickPay = true;
    this.termsConditionsAgree = false;
  }

  parseDateToLang(d: string) {
    let year = parseInt(d.substring(0, 4));
    let mon = parseInt(d.substring(4, 6)) - 1;
    let day = parseInt(d.substring(6, 8));
    let hr = parseInt(d.substring(8, 10));
    let min = parseInt(d.substring(10, 12));
    let sec = parseInt(d.substring(12));

    let dt = new Date(year, mon, day, hr, min, sec);

    return dt;
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {
    if (event.data.rs === '0') {
      this.showIFrame = false;
      return;
    }
  }

  printPage() {
    let css = '@page { size: potrait; }',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');
    style.setAttribute('type','text/css' )
    style.media = 'print';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    window.print();
  }

  cloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  setPaymentCurrency(paymentType: string) {
    if (paymentType === 'cad') {
      this.cad.nativeElement.checked = true;
      this.paymentCurrency = 'CAD';
      if (this.cadTotal === 0) {
        this.selectNext = true;
      } else {
        this.selectNext = false;
      }
    } else if (paymentType === 'usd') {
      this.usd.nativeElement.checked = true;
      this.paymentCurrency = 'USD';
      if (this.usdTotal === 0) {
        this.selectNext = true;
      } else {
        this.selectNext = false;
      }
    }
  }

  getSelectedCADTotal() {
    let tot = 0;
    let count = 0;
    this.invoiceList.forEach((item: any) => {
        if (item.invoiceCurrency === 'CAD' && item.isChecked) {
            count++;
            tot += item.balanceDue ? parseFloat(item.paymentAmount) : 0;
        }
    });
    this.cadTotal = tot;
    this.cadInvoiceCount = count;
    return tot;
  }

  getSelectedUSDTotal() {
    let tot = 0;
    let count = 0;
    this.invoiceList.forEach((item: any) => {
        if (item.invoiceCurrency === 'USD' && item.isChecked) {
            count++;
            tot += item.balanceDue ? parseFloat(item.paymentAmount) : 0;
        }
    });
    this.usdTotal = tot;
    this.usdInvoiceCount = count;
    return tot;
  }

  enableNext() {
    if (this.paymentCurrency == 'CAD' && this.cadTotal === 0) {
      return true;
    } else if (this.paymentCurrency == 'USD' && this.usdTotal === 0) {
      return true;
    } else {
      return false;
    }
  }

  termsAndConditionsHandler(status: boolean) { 
    this.termsConditionsAgree = status;
    if (status) {
      this.showIFrame = true;
      this.errorLoadingMonerisIframe = true;
      this.showCompleteIframe = true;
      this.mcoIframeLoaded = true;
      this.loadMonerisIframe();
    }
    else{
      this.termsConditionsAgree = false;
      this.showCompleteIframe = false;
      this.mcoIframeLoaded = false;
      this.checkTermsComp.setShowError(true);
    }
  }

    // Auxiliar method to filter invoice list and get only the selected ones
  filterSelectedInvoiceList(invoiceList: any) { 
    const selectedInvoiceList: any = [];
    invoiceList.forEach((item: any) => {
      if (item.isChecked) {
        selectedInvoiceList.push(item);
      }
    });
    return selectedInvoiceList;
  }

  get isEmailAddressValid() {
    const control = this.QuickPaymentForm.get('emailAddress');
    return control?.value && control.valid;
  }
  get isButtonDisabled() {
    return !this.isEmailAddressValid || this.selectNext;
  }

}
