import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalChangePasswordComponent } from 'src/app/common/shared/pop-up-modal/modal-change-password/modal-change-password.component';
import { LocalStorageService } from 'src/app/services/global/local-storage.service';
import { UserProfileService } from 'src/app/services/user-profile/user-profile.service';
import { ModalDeleteCreditcardComponent } from 'src/app/common/shared/pop-up-modal/modal-delete-creditcard/modal-delete-creditcard.component';
import { OBPPModalDialogComponent } from '../../error-component/modal-dialog/modal-dialog.component';
import { ModelDeleteEnrolledautopayuserComponent } from 'src/app/common/shared/pop-up-modal/model-delete-enrolledautopayuser/model-delete-enrolledautopayuser.component';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import { MonerisIntegrationService } from 'src/app/services/moneris-integration/moneris-integration.service';
import { CheckTermsConditionComponent } from '../../check-terms-condition/check-terms-condition.component';
import { AddCreditCard } from 'src/app/common/models/monerisCheckout';
import { ModelUserConfirmationComponent } from 'src/app/common/shared/pop-up-modal/model-user-confirmation/model-user-confirmation.component';

declare var AddCreditCard: any; 
@Component({
  selector: 'obpp-user-profile',
  templateUrl: './obpp-user-profile.component.html',
  styleUrls: ['./obpp-user-profile.component.scss'],
})
export class OBPPUserProfileComponent implements OnInit {
  isEditProfile: boolean = true;
  submitted: boolean = false;
  showIFrame: boolean = false;
  showIFrameLoading: boolean = false;
  enableSaveButton : Boolean = false;
  @ViewChild(CheckTermsConditionComponent) checkTermsComp!: CheckTermsConditionComponent;

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {
    if (event.data.event === 'cpwaCommit') {
      this.getAnnouncement();
      this.getAccountDetails();
      this.getCreditCardDetails();
    }
  }

  userInfo = {
    parentCSRUser: '',
    userEmail: '',
    userRole: '',
    isUserLoggedIn: false,
    firstName: '',
    lastName: '',
    companyName: '',
  };

  userprofile = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    newPassword: '',
    currentPassword: '',
    phoneNumber: '',
    language: '',
    phoneNumberExt: '',
    companyName: '',
    errormessage: '',
    userID: '',
    userPreferenceDto: [
      { preferenceName: 'Payment Notification', preferenceValue: false },
      { preferenceName: 'Invoice Notification', preferenceValue: false },
    ],
  };
  userprofileForm: FormGroup;
  user: any;
  roleName = 'customer';
  users = [];
  val: any;
  userEmail: string = '';
  isViewCustScreen: boolean = false;
  isLoading = false;
  isPhoneNumberValid: boolean = false;
  phoneNumberMask = [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  autopayCreditCard = {
    autopayList: [],
    creditCardList: [],
  };
  savedCards: Array<{}> = [];
  creditCardDetails: any;
  autopayList: any;
  creditCardList: any;
  payload: any;
  cpwaPayload: any;
  cpwa_frame_url: any;
  paymentEncrypt: any;
  creditDetails: any;
  loadText: string = '';
  errorLoadingMonerisIframe: boolean = false;
  monerisTicketId: string = '';
  monerisEnv: string = '';
  termsConditionsAgree = false;
  showEditCard: boolean = false;
  showUpdateError: boolean = false;
  showEditCardWarning: boolean = false;
  showAddCardWarning: boolean = false;
  showDeleteCardWarning: boolean = false;
  isCheckboxDisabled: boolean = false;
  tempCardId: string = '';
  ccOptions: any = [
    {
      path: 'assets/images/chkt_visa.svg',
      alt: 'Visa'
    },
    {
      path: 'assets/images/chkt_mastercard.svg',
      alt: 'MasterCard'
    }
  ];

  editForm = {
    obppUserId: '',
    ccMask: '',
    creditCardId: '',
    ccExpiry: '',
    cardHolderName: '',
    userEmail: '',
  };
  

  formEditEnable: any = {
    firstName: false,
    lastName: false,
    phoneNumber: false,
    phoneNumberExt: false,
    companyName: false,
  };

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private userProfileService: UserProfileService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private obppLoginService: OBPPLoginService,
    private dataSharingService: DataSharingService,
    private translateService: TranslateService,
    private monerisIntegrationService: MonerisIntegrationService
  ) {
    this.userprofileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      phoneNumberExt: ['', Validators.pattern('^[0-9]{0,6}$')],
      companyName: ['', Validators.required],
      emailAddress: [''],
      Password: [''],
      language: ['EN'],
      acceptTerms: [false],
    });
    this.translateService.get('MESSAGE_LOADING').subscribe((translation: string) => {
      this.loadText = translation;
    })
  }

  openDialog(
    title: string,
    body: string,
    firstButton: any,
    secondButton: any,
    type: string
  ): Promise<string>{
    const modelRef = this.modalService.open(ModelUserConfirmationComponent);
    modelRef.componentInstance.model_title = title;
    modelRef.componentInstance.model_content = body;
    modelRef.componentInstance.firstButtonLabel = firstButton;
    modelRef.componentInstance.secondButtonLabel = secondButton;
    modelRef.componentInstance.modelType = type;
    return modelRef.result;
  }

  ngOnInit() {
    this.userEmail = this.obppLoginService.getUserName();
    this.getAnnouncement();
    this.getAccountDetails();
    this.getCreditCardDetails();
  }

  
  enableEditField(fieldName: string) {
    this.formEditEnable[fieldName] = true;
    this.enableSaveButton = true;
    setTimeout(() => {
      document.getElementById(fieldName)?.focus();
    });
  }

  getAnnouncement() {
    this.dataSharingService.IsLoadingEnabled.next(true);
    this.userProfileService.getAnnouncement().subscribe({
      next: (res) => {
        if (res) {
          this.dataSharingService.IsLoadingEnabled.next(false);
        }
      },
      error: (error: any) => {
        console.error(error);
        this.dataSharingService.IsLoadingEnabled.next(false);
      },
    });
  }

  getAccountDetails() {
    this.dataSharingService.IsLoadingEnabled.next(true);
    const postdata = this.userEmail;
    this.userProfileService.getAccountDetails(postdata).subscribe({
      next: (res) => {
        if (res.serviceResponse.type === 'success') {
          this.userprofile = res.serviceResponse.object;
          let phoneNumber = this.userprofile.phoneNumber;
          this.userprofile.phoneNumber =
            phoneNumber.slice(0, 3) +
            '-' +
            phoneNumber.slice(3, 6) +
            '-' +
            phoneNumber.slice(6);
        }
        this.dataSharingService.IsLoadingEnabled.next(false);
      },
      error: (error: any) => {
        console.error(error);
        this.dataSharingService.IsLoadingEnabled.next(false);
      },
    });
  }

  getCreditCardDetails() {
    const postdata = this.userEmail;
    this.dataSharingService.IsLoadingEnabled.next(true);
    this.userProfileService.getCreditCardDetails(postdata).subscribe({
      next: (creditData) => {
        if (creditData) {
          this.creditCardDetails = creditData.serviceResponse.object;
          this.autopayList =
            creditData.serviceResponse.object.autoPayAccountDtoList;
          this.creditCardList =
            creditData.serviceResponse.object.creditCardDtoList;
          this.dataSharingService.IsLoadingEnabled.next(false);
        }
      },
      error: (error: any) => {
        this.dataSharingService.IsLoadingEnabled.next(false);
      },
    });
  }

  resetEditFields() {
    this.formEditEnable = {
      firstName: false,
      lastName: false,
      phoneNumber: false,
      phoneNumberExt: false,
      companyName: false,
    };
  }

  triggerEditProfile() {
    this.isEditProfile = true;
  }

  selectInvoiceNotification(event:any){
    this.enableSaveButton=true;
    if (this.userprofile.userPreferenceDto[0].preferenceName === 'Invoice Notification'){
      this.userprofile.userPreferenceDto[0].preferenceValue = event.target.checked
    }else {
      this.userprofile.userPreferenceDto[1].preferenceValue = event.target.checked
    }

  }

  selectPaymentInvoiceNotification(event:any){
    this.enableSaveButton=true;
    if (this.userprofile.userPreferenceDto[1].preferenceName === 'Invoice Notification'){
      this.userprofile.userPreferenceDto[0].preferenceValue = event.target.checked
    }else {
      this.userprofile.userPreferenceDto[1].preferenceValue = event.target.checked
    }
  }

  saveProfile(userprofile: any) {
    this.isPhoneNumberValid=false;
    this.enableSaveButton = false;
    if (userprofile.phoneNumber!=''){
      if(userprofile.phoneNumber.replace('_','').length<12){
      this.isPhoneNumberValid=true;
      }
    } if(!this.isPhoneNumberValid){
    const profile = userprofile;
    this.dataSharingService.IsLoadingEnabled.next(true);
    this.userProfileService.saveUserProfile(profile).subscribe({
      next: (res) => {
        const language = this.userprofileForm.get('language')?.value;
        if (
          profile.userPreferenceDto[0]
            .preferenceName === 'Payment Notification'
        ) {
          this.localStorageService.set(
            window.btoa('sendPaymentNotification'),
            window.btoa(
              profile.userPreferenceDto[0]
                .preferenceValue
            )
          );
        } else if (
          profile.userPreferenceDto[1]
            .preferenceName === 'Payment Notification'
        ) {
          this.localStorageService.set(
            window.btoa('sendPaymentNotification'),
            window.btoa(
              profile.userPreferenceDto[1]
                .preferenceValue
            )
          );
        }
        this.localStorageService.set('lang', language.toLowerCase());
        this.dataSharingService.IsLoadingEnabled.next(false);
        this.resetEditFields();
      },
      error: (error: any) => {
        console.error(error);
        this.dataSharingService.IsLoadingEnabled.next(false);
      },
    });
  }
    return userprofile;
  }

  cancelChanges() {
    this.resetEditFields();
    this.getAccountDetails();
    this.userprofileForm.reset();
    this.isPhoneNumberValid=false;
  }

  gotoAccountSummary() {
    this.router.navigateByUrl('/user/account-summary');
    this.dataSharingService.activeIdString.next("/user/account-summary");
    this.dataSharingService.enrollForAutoPay.next([])
  }

  showConfirmAccountRemoveModal(
    obppUserId: number,
    creditCardId: number,
    userEmail: string
  ) {
    const deletecardProcess = () => {
      this.termsConditionsAgree = false
      this.showIFrame = false
      this.showEditCard = false;
      const deletePayload = {
        obppUserId: obppUserId,
        creditCardId: creditCardId,
        userEmail: userEmail,
      };
      var enrolledAutoPay = 'No'
      if (enrolledAutoPay == 'Yes') {
        this.modalService.open(ModelDeleteEnrolledautopayuserComponent);
      } else {
        const modalRef = this.modalService.open(ModalDeleteCreditcardComponent);
        modalRef.componentInstance.removeAccount = deletePayload;
        modalRef.componentInstance.successAction.subscribe(
          ($e: any) => {
            this.getCreditCardDetails();
          },
          (error: any) => {
            console.error(error);
          }
        );
      }
      this.showDeleteCardWarning = false;
      this.showAddCardWarning = false;
      this.showEditCardWarning = false;
    }

    if(this.showDeleteCardWarning) {
      this.openDialog("UNSAVED_CHANGES_TITLE", "UNSAVED_CHANGES_MESSAGE", "Leave without saving", "Cancel", "warning")
      .then((result: string) => {
        if (result === "OK") {
          deletecardProcess();
        }
      });
    }
    else{
      deletecardProcess();
    }
  }

  changePassword() {
    let errMsg = '';
    let tit = 'CHANGE_PASS';
    let desc = 'CHANGE_PASS_INSTRUCTIONS';
    let user_data = this.userprofile;
    this.sendChangePassword(
      tit,
      errMsg,
      desc,
      'OK',
      'CANCEL_TEXT',
      'success',
      user_data
    );
  }

  sendChangePassword(
    title: string,
    errorMsg: string,
    desc: string,
    firstButtonLabel: any,
    secondButtonLabel: any,
    modalType: string,
    user_data: any
  ) {
    const modalRef = this.modalService.open(ModalChangePasswordComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.modal_desc = desc;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;
    modalRef.componentInstance.user_data = user_data;
    modalRef.componentInstance.successAction.subscribe(
      ($e: any) => {
        let desc = 'PASSWORD_UPDATED_SUCCESSFULLY';
        let title = 'PASSWORD_CHANGE';
        this.openModal(
          title,
          'PASSWORD_UPDATED_SUCCESSFULLY',
          desc,
          'OK',
          null,
          'warning'
        );
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  openModal(
    title: string,
    errorMsg: string,
    desc: string,
    firstButtonLabel: any,
    secondButtonLabel: any,
    modalType: string
  ) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.modal_desc = desc;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;
  }

  adddCard(){
    const loadIframe = () => {
      this.monerisIntegrationService.getMonerisEnvironment().subscribe({
        next: (env: any) => { 
          this.monerisEnv = env.response;
        },
        error: (error: any) => { 
          this.monerisEnv = 'prod';
        }
      });
      this.showIFrame=true;
      this.showEditCard = false;
      this.showDeleteCardWarning = false;
    }
    if(this.showAddCardWarning) {
      this.openDialog("UNSAVED_CHANGES_TITLE", "UNSAVED_CHANGES_MESSAGE", "Leave without saving", "Cancel", "warning")
      .then((result: string) => {
        if (result === "OK") {
          loadIframe();
        }
      });
    }
    else{
      loadIframe();
    }
  }

  editCard(
    obppUserId: string,
    creditCardId: string,
    ccMask: string,
    ccExpiry: string,
    cardHolderName: string,
    userEmail: string
  ) {
    this.showAddCardWarning = true;
    this.showDeleteCardWarning = true;
    const processCardEdit = () => {
      this.dataSharingService.IsLoadingEnabled.next(true);
      this.termsConditionsAgree = false
      this.showIFrame = false
      this.showEditCard = true;
      this.editForm = {
        obppUserId,
        ccMask,
        creditCardId,
        ccExpiry: ccExpiry.includes('-') ? ccExpiry.replace('-', '/') : ccExpiry,
        cardHolderName,
        userEmail,
      };
      this.dataSharingService.IsLoadingEnabled.next(false);
      this.showEditCardWarning = false;
    }
    if(this.tempCardId === ''){
      this.tempCardId = creditCardId;
      console.log(this.tempCardId, "<=====")
    }
    if(this.showEditCardWarning || this.tempCardId != creditCardId) {
      this.openDialog("UNSAVED_CHANGES_TITLE", "UNSAVED_CHANGES_MESSAGE", "Leave without saving", "Cancel", "warning")
      .then((result: string) => {
        if (result === "OK") {
          processCardEdit();
          this.tempCardId = creditCardId;
        }
      });
    }
    else{
      processCardEdit();
    }
  }

  cancelEditCard(){
    this.dataSharingService.IsLoadingEnabled.next(true);
    this.showEditCard = false;
    this.showUpdateError=false;
    this.dataSharingService.IsLoadingEnabled.next(false);
  }

  updateCreditCard() {
    this.showIFrame = false
    this.dataSharingService.IsLoadingEnabled.next(true);
    const updateCardRequestPayload = {
      obppUserId: Number(this.editForm.obppUserId),
      ccExpiry: this.editForm.ccExpiry.replace('/', ''),
      creditCardId: Number(this.editForm.creditCardId),
      userEmail: this.editForm.userEmail,
    };
    this.monerisIntegrationService.updateCreditCard(updateCardRequestPayload).subscribe(
      (response) => {
        if (response?.status === 200 || response?.success) {
          this.getCreditCardDetails();
          this.showEditCard = false;
          this.showUpdateError=false;
          this.dataSharingService.IsLoadingEnabled.next(false);
          this.showAddCardWarning = false;
          this.showDeleteCardWarning = false;
        }
        else{
          this.showUpdateError=true;
          this.showAddCardWarning = false;
          this.showDeleteCardWarning = false;
          this.dataSharingService.IsLoadingEnabled.next(false); 
        }
      },
      (error) => {
        console.error('Error updating credit card:', error);
        this.dataSharingService.IsLoadingEnabled.next(false); 
        this.showUpdateError=true;
      }
    );
  }

  termsAndConditionsHandler(status: boolean) { 
    this.termsConditionsAgree = status;
    if (status) {
      this.showIFrame = true;
      this.errorLoadingMonerisIframe = false;
      this.isCheckboxDisabled = true;
      this.loadMonerisIframe();
    }
    else{
      this.termsConditionsAgree = false;
      this.errorLoadingMonerisIframe = true;
      this.checkTermsComp.setShowError(true);
    }
    this.showEditCardWarning = true;
    this.showDeleteCardWarning = true;
  }

  updateMessageLoading() {
    this.translate.get('MESSAGE_LOADING').subscribe((translation: string) => {
      this.loadText = translation;
    });
    this.dataSharingService.LoadingLabelText.next(this.loadText);
  }

  backToMyProfile(){
    this.errorLoadingMonerisIframe = false;
    this.showIFrame = false;
    this.termsConditionsAgree = false;
  }

  loadMonerisIframe() {
    this.dataSharingService.IsLoadingEnabled.next(true);
    this.updateMessageLoading();
    const monerisPayload: AddCreditCard = {
      language: this.localStorageService.get("lang")
    };

    this.showIFrame = true;
    this.monerisIntegrationService.monarisLoadAddCard(monerisPayload).subscribe({
      next: (response: any) => { 
      if (response.response.success === 'false') {
        this.errorLoadingMonerisIframe = false;
        this.dataSharingService.IsLoadingEnabled.next(false);
        this.dataSharingService.LoadingLabelText.next('');
      }
      if (response.response.ticket) {
        this.initMonerisIframe(response.response.ticket);
      }
      },
      error: (error: any) => { 
        console.error('Error on moneris preload request: ', error);
        this.errorLoadingMonerisIframe = true;
        this.dataSharingService.IsLoadingEnabled.next(false);
        this.dataSharingService.LoadingLabelText.next('');
      }
    });
  }

  initMonerisIframe(ticketId: any) {
    const checkoutIframe = new monerisCheckout();
    checkoutIframe.setMode(this.monerisEnv);

    checkoutIframe.setCheckoutDiv('monerisCheckout', {
      ticketId,
      style: {}
    });

    checkoutIframe.startCheckout(ticketId);
    checkoutIframe.setCallback('page_loaded', (response: any) => {
      this.dataSharingService.IsLoadingEnabled.next(false);
      this.dataSharingService.LoadingLabelText.next('');
    });
    this.monerisCallbackHandling(checkoutIframe, ticketId);
    this.dataSharingService.IsLoadingEnabled.next(true);
    this.showAddCardWarning = false;
  }

  monerisCallbackHandling(monerisIframeInstance: any, monerisTicketId: string) {
    monerisIframeInstance.setCallback('cancel_transaction', (response: any) => {
      this.showIFrame = false;
      this.termsConditionsAgree = false;
      this.showEditCardWarning = false;
      this.showDeleteCardWarning = false;
      this.showAddCardWarning = false;
      this.isCheckboxDisabled = false;
    });

    monerisIframeInstance.setCallback('payment_receipt', (recepeitResp: any) => {
      this.dataSharingService.IsLoadingEnabled.next(true);
      const saveCardPayload = {
        ticket:monerisTicketId,
        userEmail: this.userEmail
      };
      this.monerisIntegrationService.saveCardDetails(saveCardPayload).subscribe({
        next: (res: any) => {
          this.getCreditCardDetails();
          this.dataSharingService.IsLoadingEnabled.next(false);
          this.showIFrame = false;
          this.termsConditionsAgree = false;
        },
        error: (err: any) => {
          this.termsConditionsAgree = false
          this.errorLoadingMonerisIframe = true;
          this.dataSharingService.IsLoadingEnabled.next(false);
        },
      });
      this.isCheckboxDisabled = false;
      this.showEditCardWarning = false;
      this.showDeleteCardWarning = false;
      this.showAddCardWarning = false;
    });
  }

  validateExpiry(event: KeyboardEvent): boolean {
    const inputChar = event.key;
    const pattern = /^[0-9/]$/;
  
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
  
  formatExpiry(event: any): void {
    let inputValue = event.target.value;
  
    if (inputValue.length === 0) {
      this.editForm.ccExpiry = "";
      return;
    }
  
    if (event.inputType === "deleteContentBackward") {
      if (inputValue.length === 3 && inputValue.charAt(2) === "/") {
        inputValue = inputValue.slice(0, 2);
      }
    }
    inputValue = inputValue.replace(/[^0-9/]/g, "");
    inputValue = inputValue.replace(/\/+/g, "/");
    const numericOnly = inputValue.replace(/\D/g, "");
    if (numericOnly.length > 4) {
      inputValue = numericOnly.slice(0, 4);
    }
    if (numericOnly.length >= 3) {
      inputValue = numericOnly.replace(/^(\d{2})/, "$1/");
    }
    if (numericOnly.length >= 2) {
      const month = parseInt(numericOnly.substring(0, 2), 10);
      if (month > 12 || month < 1) {
        inputValue = "";
      }
    }
    event.target.value = inputValue;
    this.editForm.ccExpiry = inputValue;
  }
}
