<div class="main">

    <div>
        <app-check-terms-condition [labelFirstPart]="'CHK_TERMS_AND_CONDITIONS_FIRST' | translate"
            [textLink]="'TERMS_AND_CONDITIONS' | translate" 
            [labelLastPart]="'CHK_TERMS_AND_CONDITIONS_LAST' | translate"
            [urlPdf]="'TERMS_AND_CONDITIONS_PDF' | translate"
            [error]="'ERROR_CONSENT_AGREEMENT' | translate"
            (checkboxChange)="termsAndConditionsHandler($event)"
            class="terms-and-conditions">
        </app-check-terms-condition>
    </div>
    
    <div class="payment-label">{{ "SELECT_PAYMENT_METHOD" | translate }}</div>
    
    <div class="cc-options">
        <img *ngFor="let card of ccOptions" class="cc-img" src={{card.path}}/>
    </div>

    <div *ngIf="!errorState" class="error-loading-moneris-iframe">
        <div class="alert-danger">
            {{ "DECLINED_TRANSACTION_TEXT" | translate }}
        </div>

        <button class="btn btn-primary btn-block primary-btn btn-adjust back-button" (click)="cancelPayment()">
            {{ "BACK_INVOICE_LIST" | translate }}
        </button>
    </div>

    <div *ngIf="errorLoadingMonerisIframe" class="error-loading-moneris-iframe">
        <div class="alert-danger">
            {{ "MONERIS_IFRAME_FAILED_LOAD" | translate }}
        </div>

        <button class="btn btn-primary btn-block primary-btn btn-adjust back-button" (click)="cancelPayment()">
            {{ "BACK_INVOICE_LIST" | translate }}
        </button>
    </div>

    <div class="payment-options-container">
        <label  *ngFor="let payOpt of paymentOptions" class="payment-option">
            <input  type="radio" 
                    name="payOpt" 
                    (change)="selectedPaymentOptionHandler(payOpt.paymentOption, payOpt.ccDetails !== undefined ? payOpt.ccDetails : null)" 
                    [value]="payOpt?.placeholder" 
                    [disabled]="optionsDisabled(payOpt.paymentOption)"
                    class="radio-button"/> 

                <div class="select-option">

                    <div *ngIf="payOpt.paymentOption === payWithSavedCreditCard" class="cc-details">
                        <img src={{handleCreditCardLogo(payOpt.ccDetails?.ccType)}} />
                        <div>{{payOpt.ccDetails?.ccType}}</div>
                        <div>{{payOpt.ccDetails?.ccMask}} </div>
                    </div>

                    <div *ngIf="payOpt.paymentOption === payWithMoneris && payOpt.placeholder" class="payment-option-moneris"> 
                        {{ payOpt.placeholder | translate }} 
                        
                        <div *ngIf="selectedPaymentOption === payWithMoneris && !errorLoadingMonerisIframe && showCompleteIframe">

                            <span *ngIf="mcoIframeLoaded" class="sp-zip-code">{{"ZIPCODE_POSTALCODE" | translate}}</span>

                            <div id="outerDiv" style="height: 70rem; width: 350px; padding-top: 5%; padding-bottom: 5%;">
                                <div id="monerisCheckout"></div>
                            </div>
                        </div>
                        
                    </div>

                    <div *ngIf="payOpt.paymentOption === payWithEFT">
                        {{payOpt.placeholder}} <img class="icon_tooltip" src="..\..\..\..\..\assets\images\icon-tooltip.png" title="{{'EFT_Remittance_ICON' | translate}}"/>

                        <div *ngIf="selectedPaymentOption === payWithEFT">
                            
                            <app-eft-payment 
                                [paymentDate]="'TEXT_PAYMENT_DATE' | translate" 
                                [payerName]="'TEXT_PAYER_NAME' | translate"
                                [reference]="'REFERENCE' | translate" 
                                [amount]="totalAmount" 
                                [currency]="currentCurrency"
                                [userId]="userEmail"
                                (eftPaymentInfo)="getEftPaymentInfo($event)"
                                (errorStateActive)="getErrorStateFromEft($event)">
                            </app-eft-payment>
                            
                        </div>
                    </div>                    
                </div>
        </label>
    </div>

    <div class="action-buttons" *ngIf="selectedPaymentOption !== payWithMoneris">
        <button class="btn btn-primary btn-block primary-btn submit-button" [disabled]="handleSubmitButtonState()"
                (click)="submitPayment()" role="presentation">
            {{ "SUBMIT_DISPUTE" | translate }}
        </button>
        <button class="btn btn-primary cta-blue info-btn cancel-button" (click)="cancelPayment()"
            role="presentation">
            {{ "CANCEL_TEXT" | translate }}
        </button>
    </div>

</div>