import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-model-user-confirmation',
  templateUrl: './model-user-confirmation.component.html',
  styleUrls: ['./model-user-confirmation.component.scss']
})
export class ModelUserConfirmationComponent implements OnInit {

  @Input() model_title: string = "";
  @Input() model_content: string = "";
  @Input() firstButtonLabel: string = "";
  @Input() secondButtonLabel: string = "";
  @Input() modelType: string = "";
  @Output() successAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {

  }

  successActionFunc() {
    this.successAction.emit("first");
    this.activeModal.close("OK");
  }

  cancelActionFunc() {
    this.cancelAction.emit("second");
    this.activeModal.close("Cancel");
  }

}
