<div>
  <div class="modal-header d-flex justify-content-center"
      [ngClass]="modelType == 'error' ? 'error' : modelType == 'warning' ? 'warning' : 'success'">
      <h4 class="modal-title  text-center">{{ model_title | translate }}</h4>
  </div>

  <div class="modal-body">
    <p>{{ model_content | translate }}</p>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button
      type="button"
      *ngIf="firstButtonLabel"
      class="btn btn-primary btn-block primary-btn"
      (click)="successActionFunc()"
    >
      {{ firstButtonLabel | translate }}
    </button>
    <button
      type="button"
      *ngIf="secondButtonLabel"
      class="btn btn-primary cta-blue info-btn"
      (click)="cancelActionFunc()"
    >
      {{ secondButtonLabel | translate }}
    </button>
  </div>
</div>