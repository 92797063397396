import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { EftRemittance } from 'src/app/common/models/eftRemittance';
import * as e from 'express';


const now = new Date();

@Component({
  selector: 'app-eft-payment',
  templateUrl: './eft-payment.component.html',
  styleUrls: ['./eft-payment.component.css']
})
export class EftPaymentComponent implements OnInit {
  
  @Input() title?: string;
  @Input() totalAmount?: string;
  @Input() paymentDate?: string;
  @Input() payerName?: string;
  @Input() reference?: string;
  @Input() amount?: number;
  @Input() currency?: string;
  @Input() userId?: string;

  @Output() eftPaymentInfo = new EventEmitter<EftRemittance>();
  @Output() errorStateActive = new EventEmitter<boolean>();
  
  textDescription: string = '';

  model: NgbDateStruct | undefined | string;
  eftPaymentDate: string | undefined | string;
  eftPayerName: string = '';
  eftReferenceNbr: string = '';
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  
  selectedOption: string = '';

  eftPaymentDateError: boolean = false;
  eftPayerNameError: boolean = false;
  eftReferenceNbrError: boolean = false;

  // this can look strange, but is the value returned by the datepicker when the date is not correct
  undefinedString: string = 'undefined-undefined-undefined';


  constructor(
    private translate: TranslateService,
  ) { 
    const today = new Date();
    this.minDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    };

    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 30);
    this.maxDate = {
      year: maxDate.getFullYear(),
      month: maxDate.getMonth() + 1,
      day: maxDate.getDate()
    };
    
  }

  ngOnInit(): void {
    this.errorStateActive.next(true);
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['amount'] && this.amount !== undefined) {
      this.translate.get('TEXT_EFT', { amount: this.amount }).subscribe((res: string) => {
        this.textDescription = res;
      });
    }
  }

  formatDate(date: NgbDateStruct | undefined): string {
    if (!date) {
      return '';
    }
    const day = date.day < 10 ? `0${date.day}` : date.day; //put 0 to the left on the day
    const month = date.month < 10 ? `0${date.month}` : date.month; //put 0 to the left in the month
    return `${month}/${day}/${date.year}`;
  }

  setDefaultState() {
    this.eftPaymentDateError = false;
    this.eftPayerNameError = false;
    this.eftReferenceNbrError = false;
  }

  formFieldsValidations(eftInfo: EftRemittance): boolean {

    this.eftPaymentDateError =
      (eftInfo.eftPaymentDate === '' || eftInfo.eftPaymentDate === this.undefinedString) ? true : false;
    this.eftPayerNameError = eftInfo.eftPayerName === '' ? true : false;
    this.eftReferenceNbrError = eftInfo.eftReferenceNbr === '' ? true : false;

    if (this.eftPaymentDateError || this.eftPayerNameError || this.eftReferenceNbrError) {
      return true
    } else {
      return false
    }
  }

  onFieldChange(event: any, type: string) {
    switch (type) {
      case 'eftPayerName':
        this.eftPayerName = event.target.value;
        break;
      case 'eftReferenceNbr':
        this.eftReferenceNbr = event.target.value;
        break;
      case 'eftPaymentDate':
        this.eftPaymentDate = event;
        break;
      
      default:
        break;
    } 
    this.handleEFTInformation();
  }

  handleEFTInformation() {

    this.eftPaymentDate = this.formatDate(this.model as NgbDateStruct | undefined);
    
    const eftInfo: EftRemittance = {
      eftPaymentDate: this.eftPaymentDate,
      eftPayerName: this.eftPayerName,
      eftReferenceNbr: this.eftReferenceNbr
    };

    const errorCheck = this.formFieldsValidations(eftInfo);

    if (!errorCheck) {
      this.errorStateActive.next(false);
      this.eftPaymentInfo.next(eftInfo);
      this.setDefaultState();
    } else {
      this.errorStateActive.next(true);
    }

  }
}
